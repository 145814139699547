var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticStyle:{"min-height":"120px"}},[_c('BonusListMenu',{attrs:{"mode":_vm.mode,"translation":_vm.translation},on:{"addBonus":_vm.onAddBonus}}),_c('CCardBody',{staticClass:"pt-1"},[_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":!_vm.isError && _vm.empty,"color":"info"}},[_vm._v(" No bonus products configured. "),_c('CLink',{staticClass:"strong text-primary",attrs:{"to":"/bonuses/upload"}},[_vm._v("Click here to upload")]),_vm._v(". ")],1),_c('CAlert',{staticClass:"mt-2 mb-0",attrs:{"show":_vm.isError,"color":"danger"}},[_vm._v(" An unknown error occured. Please consider logging out and in again. ")]),(!_vm.empty)?_c('CDataTable',{attrs:{"items":_vm.items,"items-per-page":10,"pagination":"","loading":_vm.isLoading,"items-per-page-select":"","cleaner":"","sorter":"","hover":"","table-filter":"","addTableClasses":"mb-0 pb-2","fields":[
                { key:'sku', label: 'SKU / Type',  sorter: true, _style: 'width: 160px' },
                { key:'imageUrl', label: '', sorter: false, _style: 'width: 60px'},                
                { key:'title', label: 'Title / Description', _classes: 'h6' }, 
                { key:'isAvailable', label:'Available', _style: 'width: 80px;'}
                ]},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"sku",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('strong',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.sku))]),_c('div',{staticClass:"d-flex align-items-start mt-1"},[(item.kind=='Bonus')?_c('CIcon',{staticClass:"mr-2 mt-0 pt-0",staticStyle:{"transform":"translateY(2px)"},attrs:{"name":"cil-gift"}}):_vm._e(),(item.kind=='Product')?_c('CIcon',{staticClass:"mr-2 mt-0 pt-0",staticStyle:{"transform":"translateY(2px)"},attrs:{"name":"cil-cart"}}):_vm._e(),_c('span',{staticClass:"mt-0 text-nowrap"},[_vm._v(_vm._s(item.kind))])],1)])]}},{key:"imageUrl",fn:function(ref){
                var item = ref.item;
return [_c('ProductsListImageColumn',{attrs:{"rowData":item,"disabled":!item.isAvailable}})]}},{key:"title",fn:function(ref){
                var item = ref.item;
return [_c('td',{class:{'opacity-1': !item.isAvailable }},[((!item.title))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Title missing")]):_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),((!item.description))?_c('CBadge',{attrs:{"color":"light"}},[_vm._v("Description missing")]):_c('span',[_vm._v(_vm._s(item.description))])],1)]}},{key:"isAvailable",fn:function(ref){
                var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":item.isAvailable ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.isAvailable ? "Available" : "Unavailable")+" ")])],1)]}}],null,false,2730899305)}):_vm._e()],1),_c('NewBonusModal',{ref:"newBonusModal",on:{"created":_vm.onBonusCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }