<template>
    <CCardHeader class="pt-2 pb-2">            
        <div class="clearfix mb-0 pb-0">             
            <h4 class="pt-1 pb float-left">Bonus List</h4>          
            <CButton v-if="isOverview && canEdit" color="primary" class="float-right" @click="onAddBonusClick"><CIcon name="cilPlus" class="mt-0 mb-1" />&nbsp;Add Bonus</CButton>
         </div>    
        <CNavbar expandable="sm" color="light" light  v-if="hasTranslations" class="mt-1 mb-1">
            <CNavbarNav>
            <CNavItem active to="/bonuses" :class="{strong: isOverview}" :disabled="isLoading"><CIcon name="cil-list-rich" size="sm" class="mb-1 mr-2" />Overview</CNavItem>
            <CDropdown
                v-if="hasTranslations"
                toggler-text="Translations"
                in-nav
                :class="{strong: isTranslation}"
                :disabled="isLoading"
            >
                <template #toggler-content>
                    <CIcon name="cil-globe-alt" size="sm" class="mb-1 mr-2" />
                    <span style="color:black;">Translations<template v-if="isTranslation"> ({{ translation }})</template></span>
                </template>
                <CDropdownItem v-for="tl in translations" :key="tl" :to="`/bonuses/translation/${tl}`">{{ tl }}</CDropdownItem>
            </CDropdown>
            </CNavbarNav>
        </CNavbar>
    </CCardHeader>
</template>
<script>
import { mapGetters } from 'vuex';
import { EDIT_REWARD, mapPermissions } from '@/permissions';

export default {
    props: {
        mode: String,
        translation: String    
    },
    data() {
        return {
            selectedMode: this.mode,
        }
    },
    emits: ["addBonus"],
    methods: {
        onAddBonusClick() {
            this.$emit("addBonus")
        }
    },
    computed: {
        ...mapPermissions({
            canEdit: EDIT_REWARD
        }),
        ...mapGetters({
            translations: 'products/translations',
            isLoading: 'products/loading',
        }),
        hasTranslations() {
            return this.translations != null && this.translations.length > 1;
        },
        isOverview() { return this.selectedMode === 'overview' || !this.selectedMode },
        isTranslation() { return this.selectedMode === 'translation'}
    },
    watch: {
        mode(newMode) {
            this.selectedMode = newMode;
        }
    }
}
</script>