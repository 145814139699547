<template>
    <td style="width: 60px;" class="pb-2 pt-2">
        <div style="background: #ddd; width: 60px;" :class="{'opacity-1': disabled }">
            <img v-if="(!item.imageMissing)" width="60" height="60" :src="item.imageUrl" :thumbnail="true" @error="imgMissing($event, item)" />
            <CIcon v-if="(item.imageMissing)" name='cil-image' size="custom" width="60" height="60"></CIcon>
        </div>
    </td>
</template>
<script>
export default {
    props: {
        rowData: Object,
        disabled: Boolean
    },
    data() {
        return {
            item: this.rowData
        }
    },
    methods: {
        imgMissing(e, item) {            
            this.$set(item, 'imageMissing', true);
        }
    },
    watch: {
        rowData(value) {
            this.item = value;
        }
    }
}
</script>