<template>
<CCard style="min-height: 120px;"> 
    <BonusListMenu :mode="mode" :translation="translation" @addBonus="onAddBonus" />
    <CCardBody class="pt-1">     
        <CAlert :show="!isError && empty" color="info" class="mt-2 mb-0">
           No bonus products configured.&nbsp;<CLink to="/bonuses/upload" class="strong text-primary">Click here to upload</CLink>.
        </CAlert>
        <CAlert :show="isError" color="danger" class="mt-2 mb-0">
           An unknown error occured. Please consider logging out and in again.
        </CAlert>
        <CDataTable
            v-if="!empty"
            :items="items"
            :items-per-page="10"
            pagination
            :loading="isLoading"      
            items-per-page-select       
            cleaner
            sorter
            hover
            table-filter
            addTableClasses="mb-0 pb-2"
            @row-clicked="rowClicked"
                :fields="[
                { key:'sku', label: 'SKU / Type',  sorter: true, _style: 'width: 160px' },
                { key:'imageUrl', label: '', sorter: false, _style: 'width: 60px'},                
                { key:'title', label: 'Title / Description', _classes: 'h6' }, 
                { key:'isAvailable', label:'Available', _style: 'width: 80px;'}
                ]"
            >      
            <template #sku="{item}">
                <td>
                    <strong class="text-nowrap">{{item.sku}}</strong>                    
                    <div class="d-flex align-items-start mt-1">                        
                        <CIcon v-if="item.kind=='Bonus'" name="cil-gift" class="mr-2 mt-0 pt-0" style="transform: translateY(2px)" />                    
                        <CIcon v-if="item.kind=='Product'" name="cil-cart" class="mr-2 mt-0 pt-0" style="transform: translateY(2px)" />    
                        <span class="mt-0 text-nowrap">{{item.kind}}</span>
                    </div>
                </td>
            </template>    
            <template #imageUrl="{item}">
                <ProductsListImageColumn :rowData='item' :disabled="!item.isAvailable" />
            </template>
            <template #title="{item}">
                <td :class="{'opacity-1': !item.isAvailable }">
                    <CBadge v-if="(!item.title)" color="light">Title missing</CBadge><strong v-else>{{ item.title }}</strong><br />
                    <CBadge v-if="(!item.description)" color="light">Description missing</CBadge><span v-else>{{ item.description }}</span>
                </td>
            </template>    
            <template #isAvailable="{item}">
                <td>
                <CBadge :color="item.isAvailable ? 'success' : 'danger'">
                    {{ item.isAvailable ? "Available" : "Unavailable" }}
                </CBadge>
                </td>
            </template>              
        </CDataTable>
    </CCardBody>
    <NewBonusModal ref="newBonusModal" @created="onBonusCreated" />
</CCard>
</template>
<script>
import BonusListMenu from '@/components/BonusListMenu';
import NewBonusModal from '@/components/modals/NewBonusModal'
import ProductsListImageColumn from '@/components/ProductsListImageColumn';
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    props: {
        translation: String
    },
    components: {
        BonusListMenu,
        ProductsListImageColumn,
        NewBonusModal
    },
    data() {
        return {
            isError: false
        }
    }, 
    computed: {        
        ...mapGetters({ 
            items: 'products/products',
            isLoading: 'products/loading',
            merchantId: 'merchantId' 
        }),
        mode() {
            return this.translation ? "translation" : "overview";
        },
        empty() {
            return !this.isLoading && this.items && this.items.length == 0;
        }
    },
    methods: {
        ...mapActions({
            loadBonuses: actionNames.LOAD_BONUSES,
            loadBonusesTranslation: actionNames.LOAD_BONUSES_TRANSLATION
        }),
        async load() {
            this.isError = false;
            try {
                if (this.merchantId != null) {
                    if (this.translation) {
                        await this.loadBonusesTranslation({translation: this.translation})
                    }
                    else {
                        await this.loadBonuses();
                    }
                }
            }
            catch (e)
            {
                this.isError = true;
            }
        },
        rowClicked(item) {
            const tl = (this.mode == "translation" && this.translation) ? `?tl=${this.translation}` : ''
            this.$router.push(`/bonuses/view/${item.sku}${tl}`);
        },
        onAddBonus() {
            this.$refs.newBonusModal.show();
        },
        async onBonusCreated(sku) {
            if (sku) {
                this.$router.push(`/bonuses/view/${sku}`);
            }
            else {
                await this.load();
            }
        }
    },
    watch:
    {
        translation() {
            this.load();
        }
    },
    created() {
        this.load();
    }
}
</script>
