<template>
 <div>
     <div class="form-group form-row mt-1 mb-0 ">
        <div class="bonus-label-col">
            <label class="col-form-label strong" >Reward Type</label>
        </div>
        <div class="col-sm-6 form-check form-check-inline" role="group">
            <CInputRadio label="Existing Product" name="g1" class="ml-0 pl-0 mr-3" value="Product" add-input-classes="ml-2"
                            :checked.sync="productSelected" 
                            :disabled='loading'
            />
            <CInputRadio label="Create Bonus Product" name="g1" class="ml-0 pl-0" value="Bonus" 
                        :checked.sync="bonusSelected" 
                        :disabled='loading'
            />
        </div>        
    </div>
    <div class="row">
        <div class="col bonus-label-col">&nbsp;</div>
        <CAlert color="info" class="py-1 px-2 col mr-3 mb-0 ml-3">
            <template v-if="productSelected">
                Use an existing product to send out as a reward.
            </template>
            <template v-if="bonusSelected">
                Create a new bonus product as reward. A bonus product must have its own unique SKU that is not a product SKU.
            </template>
        </CAlert>
    </div>
    <template v-if="productSelected">
        <div class="row mt-2">
            <div class="col-sm-8 mr-0 pr-0">
            <CSelect class="mb-0 ml-0 mr-0 mt-2 pl-0 pr-0 col" add-input-classes="pr-0 mr-0" add-wrapper-classes="px-0 mr-0" add-label-classes="strong px-0 bonus-label-col"
                     horizontal placeholder="Select product ..." 
                     :options="productOptions" label="Product" 
                     :value.sync="selectedProduct"
            />
            </div>
            <div v-if="selectedProduct" class="col mt-2 alert-secondary p-0 border-secondary ml-0 pl-0" style="max-width:35px; max-height: 35px">
                <img v-if="showImage" @error="imgMissing($event)" width="33" height="33" :src="productImage" class="m-0 p-0" />
                <CIcon v-if="!showImage" name='cil-image' size="custom" width="33" height="33"></CIcon>
            </div>
        </div>
    </template>
    <template v-if="bonusSelected">  
        <div class="row mt-3">
            <CInput horizontal label="SKU" 
                    class="ml-0 col mb-0"
                    add-label-classes="strong ml-0 pl-0 bonus-label-col"
                    add-wrapper-classes="pl-0 ml-0"
                    maxlength="32"     
                    description="Unique identifier for the bonus product. Up to 32 characters: A-Z a-z 0-9 - _ +"
                    :isValid="isSkuValid"  
                    v-model="sku"             
            />            
        </div>      
        <div class="row mt-2">
            <CInput horizontal label="Title" 
                    class="ml-0 col mb-0"
                    add-label-classes="strong ml-0 pl-0 bonus-label-col"
                    add-wrapper-classes="pl-0 ml-0"
                    maxlength="80"
                    :isValid="isTitleValid"
                    v-model="title"    
            />            
        </div>
        <div class="row mt-2 mb-0">
            <CTextarea horizontal label="Description" 
                       class="ml-0 mb-0 col"
                       add-label-classes="strong ml-0 pl-0 bonus-label-col"
                       add-wrapper-classes="pl-0 ml-0"
                       maxlength="150"
                      :is-valid="isDescriptionValid"
                       v-model="description"                       
            />
        </div>
    </template>
 </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    data() {
        return {
            loading: false,
            productSelected: false,
            bonusSelected: true,
            selectedProduct: null,
            showImage: true,

            sku: null,
            title: null,
            description: null
        }
    },
    computed: {
        ...mapGetters({
            products: 'products/nonBonus'
        }),
        productOptions() {
            return this.products.map(p => { return { value: p.sku, label: p.sku }});
        },
        productImage() {
            if (this.selectedProduct)
            {
                const selected = this.products.find(o => o.sku == this.selectedProduct);
                return selected.imageUrl;
            }
            else 
            {
                return null;
            }
        },
        canCreate() {
            if (this.productSelected && this.selectedProduct != null) {
                return true;
            }            

            return this.isValid() !== false;
        }
    },
    methods: {
        ...mapActions({
            createBonus: actionNames.CREATE_BONUS
        }),
        imgMissing() {
            this.showImage = false;
        },
        emitCanCreate() {
            this.$emit("canCreate", this.canCreate);
        },
        async save() {
            if (this.canCreate) 
            {
                if (this.productSelected) {
                    await this.createBonus({sku:this.selectedProduct, bonus: {isExistingProduct: true}});
                    return this.selectedProduct;
                }
                else if (this.bonusSelected) {
                    await this.createBonus({ sku:this.sku, bonus:{isExistingProduct: false, title: this.title, description: this.description}});
                    return this.sku;
                }
            }
        },
        isSkuValid: sku => sku && sku.length <= 32 && sku.match(/^[A-Za-z0-9_+-]+$/) ? null : false,
        isTitleValid: (title) => title && title.length <= 80 ? null : false,
        isDescriptionValid: (d) => d && d.length <= 150 ? null : false,
        isValid() {
            if (this.bonusSelected) {

                return this.isSkuValid(this.sku) !== false && this.isTitleValid(this.title) !== false&& this.isDescriptionValid(this.description) !== false;
            } 
            else 
            {
                return false;
            }
        }
    },
    watch: {
        productSelected(checked) {
            if (checked) {
                this.bonusSelected = false;
            }
            this.emitCanCreate();
        },
        bonusSelected(checked) {
            if (checked) {
                this.productSelected = false;
            }
            this.emitCanCreate();
        },
        selectedProduct() {
            this.showImage = true;
            this.emitCanCreate();
        },
        sku() { this.emitCanCreate(); },
        title() { this.emitCanCreate(); },
        description() { this.emitCanCreate(); }
    },
    created() {
        this.selectedProduct = null;
        this.emitCanCreate();
    }
}
</script>
<style scoped>
.text-black {
    color: black;
}
.label-col {
    min-width: 120px;
    width: 120px !important;
}
.border-left {
    border-left: solid 1px #c4c9d0 !important;
}
.border-bottom {
    border-bottom: solid 1px #c4c9d0 !important;
}
.border-right {
    border-right: solid 1px #c4c9d0 !important;
}
</style>
<style>
.wide-label {
    width: 100px !important;
}
</style>
<style>

</style>