<template>
    <CModal
    color="primary"
    size="lg"
    addContentClasses="large-modal"
    title="Add New Bonus"
    :closeOnBackdrop='false'
    :show.sync="showModal"
    >
        <BonusEditor v-if="showModal" ref="editor" @canCreate="onCanCreateChange" />
        <template #footer>
            <div v-if="loading" class="mr-auto d-flex align-items-center">
                <CSpinner class="mr-3" size="sm" /><strong>Creating bonus ...</strong>
            </div>
            <CButton :disabled='loading' color="secondary" @click="onCancel">Cancel</CButton>
            <CButton color="success" @click="onSave" :disabled="!canCreate">Create Bonus<CSpinner size="sm" class="ml-2" v-if="loading" /></CButton>
        </template>
    </CModal>    
</template>
<script>
import { mapGetters } from 'vuex';
import BonusEditor from '@/components/BonusEditor'
export default {
    components: {
        BonusEditor
    },
    data() {
        return {
            showModal: false,
            canCreateBonus: false
        }
    },
    emits: ["created"],
    computed: {
        ...mapGetters({
            loading: 'products/loading'
        }),
        canCreate() {
            if (this.loading) {
                return false;
            }
            return this.canCreateBonus;
        }
    },
    methods: {
        /* eslint-disable */
        show() {
            // this.title = "New Footer Link";
            // this.index = 1000;
            // this.$refs.editor.new();
            this.showModal = true;
        },
        onCancel() {
            this.showModal = false;
        },
        onCanCreateChange(canCreate) {
            this.canCreateBonus = canCreate;
        },
        async onSave() {
            try {
                const sku = await this.$refs.editor.save();
                this.$emit("created", sku);
            }
            catch {}
            this.showModal = false;
        }
    }
}
</script>

